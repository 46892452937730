body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(3,48,105);
background: linear-gradient(90deg, rgba(3,48,105,1) 0%, rgba(9,121,69,1) 0%, rgba(0,104,255,1) 200%);
   color: white;
}
.card{
  color: white;
  margin: 15px;
  padding: 20px;
  background-color: #42ba96;
}

input{
  background-color: #b6dbfd;
  border: 3px solid #160b53;
  border-radius: 8px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  
}
select{
  background-color: #b6dbfd;
  padding: 5px;
  border-radius: 5px;
}
.nbmonname{
  font-weight: 600;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.value-input{
  width: 50px;
}
