.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.realmhunter-logo{
  width: 500px;
  margin: 40px auto;
}
.fa-heart{
  color: red;
}
.fa-khanda{
  color: #45df6d;
}
.fa-shield-alt{
  color: #ffd400;
}
.nb-description{
  text-align: center;
}
.card-icons{
  margin-top: 0px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3px;
}
.card-body{
  padding: 0;
}
.nb-gallery-showcase{
  margin-top: 10px;
}
.card-icons i:first-child{
  padding-left: 0;
}
.card-icons i{
  padding-left: 10px;
  padding-right: 4px;
}
.realmhunter-logo img{
  width: 100%;
}
.navi-right{
  float: right;
}
.navi-left{
  float: left;
}
.back-marketplace-button{
  display: block;
  float: right;
  text-align: right;
  color: white;
  text-decoration: none;
  font-weight: 700;
  background: rgba(100,100,100,0.3);
  padding: 10px;
  border-radius: 6px;
  margin-top: 5px;
}
.row-solo{
  width: 100%;
}
.back-marketplace-button:hover{
  color: white;
  background: rgba(100,100,100,0.5);
}

ul.nav{
  width: 100%;
  height: 60px;
  display: block;
}

.navigation{
  margin-top: -100px;
  margin-bottom: 40px;
}

.navigation button{
  background: none;
  border: none;
}
.solo-nb{
  margin: 0 auto;
}
.solo-nb .fas{
  display: inline-block;
  word-wrap:unset;
  width: 100px;
  padding-top: 5px;
}
.fapot{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 3px;
}
.solo-nb a, .nb-gallery-showcase a{
  text-decoration: none;
}
.nbmon-card{
  text-decoration: none !important;
}
.navi-login{
  background: linear-gradient(to bottom, #42ba96 5%, #3ab993 100%) !important;
  border:1px solid #35a583 !important;
  color: white !important;
}
.navigation ul{
  padding: 0px 15px;
  color: white;
}
.navigation li {
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  padding: 3px 10px;
  margin: 0px 10px;
  text-shadow:0px 1px 0px #528009;
  background: rgba(255,255,255,0.1);
  border-radius: 5px;

}
.nav-link{
  border: none !important;
  color: white;
}
.nav-link:hover{
  border: none !important;
  color: white;
}
.navigation li:hover{
  background: rgba(255,255,255,0.15);
}
.navigation li:active {
  position:relative;
  top:1px;
}
.nav-tabs{
  border: none;
}

.balance-show{
  position: absolute;
  display: block;
  top: 5px;
  right: 5px;
  font-weight: 700;
  text-shadow: none !important;
}
.nbmonname{
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}
.nb-link{
  text-decoration: none;
}
.card{
  background: rgba(0,0,0,0.1);
  border: none;
}
.nb-card{
  background: none;
  border-radius: 8px;
  padding: 0 !important;
}
.card-header{
  padding: 5px 0px;
  background: none;
  border: none;
}
.nb-inside-card{
  background: rgb(63,94,251);
  background: rgba(255,255,255,0.05);
-webkit-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.05);
-moz-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.05);
box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.05);
  width: 100%;
  padding: 30px;
  padding-top: 5px;
  padding-bottom: 15px;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
}
.nb-image{
  overflow: hidden;
  height: 160px;
  text-align: center;
}
.nbmon-background{
  position: absolute;
  opacity: 25%;
  top: -70px;
  left: 0;
  z-index: -1;
}
.card-header .title{
  margin-top: 5px;
}
.nb-image img{
  width: 100%;
}
.filter-clear{
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  font-size: 15px;
}
.form-check-input{
  background: rgba(255,255,255,0.3);
  border: none;
  box-shadow: none !important;
}
.form-check-input:checked{
  background: rgba(255,255,255,0.3);
  box-shadow: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.sort-block{
  position: absolute;
  top: 0;
  right: 10px;
}
.gallery-block{
  position: relative;
  padding-top: 30px;
}
.nb-price b{
  font-size: 23px;
}
.nb-price i{
  font-style: normal;
}
.nb-description{
  display: none;
}

.form-range-label{
  display: block;
}
.form-range-input{
  margin: 0;
  background: rgba(255,255,255,0.3) !important;
  appearance: none;
  border: none;
  height: 7px;
  border-radius: 3px;
  padding: 0;
}
.form-range-input::-webkit-slider-thumb{
  appearance: none;
  background: rgba(255,255,255,0.6) !important;
  width: 15px;
  height: 15px;
  border-radius: 6px;
}
.form-range-input::-moz-slider-thumb{
  appearance: none;
  background: rgba(255,255,255,0.6) !important;
  width: 15px;
  height: 15px;
  border-radius: 6px;
}
.form-range-label{
  font-size: 15px;
  margin-bottom: -5px;
}
.sort-block button{
  background: rgba(255,255,255,0.1);
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 10px;
  margin: 0px 5px;
}
.largelogin{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: rgba(3,48,105,0.6);
  z-index: 1;
}
.login-title{
  font-weight: 300;
  font-size: 13px;
  padding-bottom: 15px;
  display: block;
}
.login-block{
  margin: 70px auto;
  text-align: center;
  width: 300px;
  min-height: 200px;
  background: linear-gradient(90deg, rgba(3,48,105,1) 0%, rgba(9,121,69,1) 0%, rgba(0,104,255,1) 200%);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 15px;
}
.login-showup{
  margin-top: 15px;
}
.login-block input{
  border: none;
  background: rgba(255,255,255,0.2);
  padding: 20px 15px;
  height: 25px !important;
  color: white;
}
.login-block input:focus-visible{
  border: none !important;
  box-shadow: none;
  outline: none;
  background: rgba(255,255,255,0.3);
}
.login-block button{
  color: white;
  background: rgba(255,255,255,0.2);
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.login-block button:hover{
  background: rgba(255,255,255,0.3);
}

.App-link {
  color: #61dafb;
}
.searchWrapper{
  background: rgba(0,0,0,0.1);
  border: none !important;
}
.searchBox{
  color: white !important;
}
.optionContainer{
  color: white;
  background: rgba(83,152,120,1);
  border: none !important;
}
.option:hover{
  background: rgba(9,121,69,1) !important;
}
.highlightOption{
  background: rgba(83,152,120,1) !important;
}
.chip {
  background: rgba(83,152,120,1) !important;
}

::-webkit-scrollbar{
  background: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
  background: rgba(255,255,255,0.2);
}

.hidden-nbmon{
  display: none;
}
.mintAnEgg{
  margin: 0 auto;
  display: block;
  background: rgba(0,0,0,0.1);
  border: none;
  padding: 30px;
  color: white;
  border-radius: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.balance-show{
  width: 99%;
}
.useremail-show{
  float: left;
  text-align: left;
}

.balance-part{
  text-align: right;
  float: right;
}

.mintAnEgg{
  background: none;
  width: 250px;
}
.mintAnEgg img{
  width: 100%;
}
.nb-egg-container{
  margin: 0 auto;
}
.nb-egg{
  background: none;
  position: relative;
}
.egg-opened-back{
  position: absolute;
  top: 0;
  width: 250px;
}
.egg-nbmon{
  position: absolute;
  top: 0;
  width: 150px;
  left: 80px;
}
.egg-opened-front{
  position: absolute;
  top: 0;
  width: 250px;
}
.full-egg-description{
  margin-top: 250px;  
}
.confetti-bg{
  position: absolute;
  top: 0;
  left: -400px;
  width: 1200px;
}